
$(function() {
    $('body').on('click', '.page-scroll a', function(event) {
        $('html')
          .stop()
          .animate(
            { scrollTop: $($(this).attr('href')).offset().top },
            500,
            'easeInOutExpo'
          );
        event.preventDefault();
    });
});

// Floating label headings for the contact form
// $(function() {
//     $("body").on("input propertychange", ".floating-label-form-group", function(e) {
//         $(this).toggleClass("floating-label-form-group-with-value", !! $(e.target).val());
//     }).on("focus", ".floating-label-form-group", function() {
//         $(this).addClass("floating-label-form-group-with-focus");
//     }).on("blur", ".floating-label-form-group", function() {
//         $(this).removeClass("floating-label-form-group-with-focus");
//     });
// });
